import * as moment from 'moment';

export class Tasks {
    id	: number;
    name	: string;
    description	: string;
    created	: moment.Moment;
    updated	: moment.Moment;
    projectId	: number;
    accountId	: number;
    xeroPayItemId	: number;
}
