import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { NotifierModule } from 'angular-notifier';
import { MatCardModule, MatDividerModule, MatProgressSpinnerModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDeleteComponent } from './confirm-delete.component';

import { PeriodFormatterPipe } from './period-formatter.pipe';
import { FilterByNamePipe } from './filter-by-name.pipe';

import { ShowAuthedDirective } from './show-authed.directive';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [
    ConfirmDeleteComponent,
    PeriodFormatterPipe,
    FilterByNamePipe,
    ShowAuthedDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MomentModule,
    FontAwesomeModule,
    NotifierModule,
    MatCardModule,
    MatDividerModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MomentModule,
    FontAwesomeModule,
    NotifierModule,
    ConfirmDeleteComponent,
    PeriodFormatterPipe,
    FilterByNamePipe,
    ShowAuthedDirective,
    MatCardModule,
    MatDividerModule,
    FlexLayoutModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [ConfirmDeleteComponent]
})
export class SharedModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(fas);
  }
}
