import * as moment from 'moment';

export class Timesheets {
    id	: number;
    weekEnding	: moment.Moment;
    totalHours	: number;
    submitted	: boolean;
    dateSubmitted	: moment.Moment;
    approved	: boolean;
    dateApproved	: moment.Moment;
    created	: moment.Moment;
    updated	: moment.Moment;
    userId	: number;
    accountId	: number;
}
