import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimesheetsComponent } from './timesheets.component';
import { TimesheetListComponent } from './timesheet-list/timesheet-list.component';

const routes :Routes=[{
  path:'timesheets',component:TimesheetsComponent,
  children:[
    {path:'',component:TimesheetListComponent}
  ]
}];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TimesheetsRoutingModule { }
