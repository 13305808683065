import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SyncDataService } from '../sync-data.service';

@Component({
  selector: 'app-sync-upload',
  templateUrl: './sync-upload.component.html',
  styles: []
})
export class SyncUploadComponent implements OnInit {

  results: any;

  constructor(private dataService: SyncDataService, private http: HttpClient) { }

  ngOnInit() {

    this.http.get('api/sap/import').subscribe(res => {
      console.log(res);
      this.results = res;
      this.dataService.setData(res);
    });
  }


}
