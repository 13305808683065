import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Users } from '../../shared/models';
import { JwtService } from './jwt.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject = new BehaviorSubject<Users>(new Users());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  /**
   * 
   * @param apiService 
   * @param jwtService 
   */
  constructor(private apiService: ApiService, private jwtService: JwtService) {
    
    console.log(this.currentUser);

  }

  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('/api/user')
        .subscribe(
          data => {
            data['token']=window.localStorage['token'];
            this.setAuth(data)},
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  attemptAuth(credentials): Observable<Users> {
    return this.apiService.post('/api/auth/signin', credentials)
      .pipe(
        map((data: any) => {
          this.setAuth(data);
          return data;
        })
      );
  }

  setAuth(user: Users) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new Users());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }
 public get currentUserValue(): Users{
    return this.currentUserSubject.value;
  }
  // getAll(queryParams = {}): Observable<User[]> {
  //   return this.http.get<User[]>(`${environment.apiBaseUrl}/users`, { params: queryParams });
  //     // .pipe(
  //     //   map(users => users.map(e => Object.assign(new User(), e)))  // TODO: find out if this is necessary/beneficial
  //     // );
  // }

  // get(id: number): Observable<User> {
  //   return this.http.get<User>(`${environment.apiBaseUrl}/users/${id}`);
  // }

  // create(employee: User): Observable<User> {
  //   return this.http.post<User>(`${environment.apiBaseUrl}/users`, employee);
  // }

  // update(employee: User): Observable<User> {
  //   return this.http.put<User>(`${environment.apiBaseUrl}/users/${employee.id}`, employee);
  // }

  // delete(id: number): Observable<User> {
  //   return this.http.delete<User>(`${environment.apiBaseUrl}/users/${id}`);
  // }

  // getByEmail(email: string): Observable<User> {
  //   return this.http.get<User>(`${environment.apiBaseUrl}/users/by-email/${email}`);
  // }

 

}
