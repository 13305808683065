import { NgModule } from '@angular/core';
import { ClientsComponent } from './clients.component';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { SharedModule } from '../shared/shared.module';
import { ClientsViewComponent } from './clients-view/clients-view.component';
import { ClientsCreateComponent } from './clients-create/clients-create.component';

@NgModule({
  declarations: [
    ClientsComponent,
    ClientsListComponent,
    ClientsViewComponent,
    ClientsCreateComponent
  ],
  imports: [
    ClientsRoutingModule,
    SharedModule,
  ]
})
export class ClientsModule { }
