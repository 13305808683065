import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ClientsService } from '../shared/clients.service';
import { Clients } from 'src/app/shared/models';
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/confirm-delete.component';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styles: [' .client-card{margin-top:10px;} .client-card:hover{background-color:#A3A3A3;}']
})
export class ClientsListComponent implements OnInit {
  loading = true;
  archived = false;
  deleting = false;
  clientsList : Clients[];
  constructor(private clientsService : ClientsService, private router : Router, private location: Location, private modalService: NgbModal) { }

  ngOnInit() {
    this.archived = false;
    if (this.router.url.includes("archived")){
      this.viewArchived();
    } else {
      this.viewLive();
    }
    
    
  }

  viewClient(client){
    this.router.navigateByUrl('clients/' + client.id)
  }

  addNew(){
    this.router.navigateByUrl('clients/create')
  }

  viewLive(){
    this.loading = true;
    this.archived = false;
    this.location.replaceState('/clients/');
    this.clientsService.getAll().subscribe( (res)=>{
      this.clientsList=res['data'];
      for(let client of this.clientsList){
        client.projects = 0
        this.clientsService.getProjects(client.id).subscribe( (res)=>{
          client.projects=res['total']
        },
        (err)=>{

        },
        ()=>{
        })
      }
      this.loading=false;
    },
    (err)=>{

    },
    ()=>{
      // this.loading=false;
    })
  }

  viewArchived(){
    this.loading = true;
    this.archived = true;
    this.location.replaceState('/clients/archived');
    this.clientsService.getArchived().subscribe( (res)=>{
      this.clientsList = res['data'];
    },
    (err)=>{
    },
    ()=>{
      this.loading = false;
    })
  }

  restore(id : number){
    var client : Clients = new Clients;
    client.id = id;
    this.clientsService.restore(client).subscribe((res)=>{
      this.router.navigateByUrl('clients/' + res.id);
    })
  }

  delete(id : number, name : String){
    this.deleting = true;

    const modalRef = this.modalService.open(ConfirmDeleteComponent, { size: 'lg'});
    modalRef.componentInstance.title = "Delete Client?";
    modalRef.componentInstance.message = "Are you sure you want to delete <b>"+name+"</b>.\nThis cannot be un-done.";

    modalRef.result.then((confirmed) => {
      this.loading = true;
      var client : Clients = new Clients;
      client.id = id;
      this.clientsService.permanentDelete(client).subscribe((res)=>{
        this.viewArchived();
      },
      (err)=>{
        console.log("Could not delete");
      })
    }, (cancelled) => {});
  }
}
