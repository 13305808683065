import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ConfigRoutingModule } from './config-routing.module';
import { ConfigComponent } from './config.component';
import { ConfigDefaultComponent } from './config-default.component';

@NgModule({
  declarations: [
    ConfigComponent,
    ConfigDefaultComponent,
  ],
  imports: [
    SharedModule,
    ConfigRoutingModule
  ],
  entryComponents: [
  ]
})
export class ConfigModule { }
