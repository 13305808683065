import * as moment from 'moment';

export class Pos {
    id	: number;
    proposal	: string;
    description	: string;
    number	: string;
    totalCost	: number;
    payTerms	: number;
    length	: number;
    current	: boolean;
    startDate : moment.Moment;
    endDate	: moment.Moment;
    created	: moment.Moment;
    updated	: moment.Moment;
    deleted	: moment.Moment;
    clientId	: number;
    projectId	: number;
    accountId	: number;
    step	: number;
    notes	: string;
}
