import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SyncComponent } from './sync.component';
import { SyncUploadComponent } from './sync-upload/sync-upload.component';
import { AuthGuard } from '../core/services';

const routes: Routes = [
  {
    path: 'sync',
    component: SyncComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: SyncUploadComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SyncRoutingModule { }
