import { Injectable } from '@angular/core';

@Injectable()
export class SyncDataService {

  data: any = {
    '500700': {
      phone: '',
      address: '',
      sapId: 500700,
      name: 'Paul James TWYFORD',
      type: 'permanent',
      position: {
        name: 'Electrician Experienced'
      },
      department: {
        name: 'Electrical Maintenance 2',
        sapId: 30084173
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30025284,
            name: 'Authorised Personal Lockholder Mine Site'
          },
          startDate: '2008-03-20T00:00:00.000Z',
          endDate: '2010-03-19T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022106,
            name: 'Conduct 5 Step/Job Hazard Analysis'
          },
          startDate: '2008-06-05T00:00:00.000Z',
          endDate: '2012-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022640,
            name: 'Operate 4WD Vehicle'
          },
          startDate: '2008-06-11T00:00:00.000Z',
          endDate: '2010-06-10T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022124,
            name: 'LF - Forklift Truck'
          },
          startDate: '2008-07-01T00:00:00.000Z',
          endDate: '2013-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2008-07-01T00:00:00.000Z',
          endDate: '2013-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022444,
            name: 'Operate Light Vehicle'
          },
          startDate: '2008-07-02T00:00:00.000Z',
          endDate: '2010-07-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022588,
            name: 'CB Light Vehicle Driving Permit'
          },
          startDate: '2008-07-02T00:00:00.000Z',
          endDate: '2013-07-01T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30080399,
            name: 'HV Switching Operations - Certificate'
          },
          startDate: '2009-01-23T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022426,
            name: 'JLG 1250 EWP'
          },
          startDate: '2010-03-02T00:00:00.000Z',
          endDate: '2012-03-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022108,
            name: 'Basic First Aid'
          },
          startDate: '2010-05-05T00:00:00.000Z',
          endDate: '2013-05-04T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022310,
            name: 'JLG1930E Scissor Lift'
          },
          startDate: '2010-07-01T00:00:00.000Z',
          endDate: '2012-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022425,
            name: 'JLG 450 AJ EWP'
          },
          startDate: '2010-07-01T00:00:00.000Z',
          endDate: '2012-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022590,
            name: 'CB Level 3 Pit Permit'
          },
          startDate: '2010-12-29T00:00:00.000Z',
          endDate: '2012-11-10T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2011-05-19T00:00:00.000Z',
          endDate: '2012-05-18T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022297,
            name: 'Hyundai 30DF-7 Forklift'
          },
          startDate: '2011-06-15T00:00:00.000Z',
          endDate: '2013-06-14T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022052,
            name: 'Cert IV Training & Assessment'
          },
          startDate: '2011-07-07T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2011-08-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022111,
            name: 'CB Induction - Online'
          },
          startDate: '2011-08-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022113,
            name: 'CC Induction - Online'
          },
          startDate: '2011-08-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022585,
            name: 'Perth Induction - Online'
          },
          startDate: '2011-08-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30025284,
            name: 'Authorised Personal Lockholder Mine Site'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '2012-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036199,
            name: 'Fatigue Management Training - General'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '2014-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022112,
            name: 'CB Induction - Site'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038956,
            name: 'Cross Cultural Awareness'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30047732,
            name: 'Body Smart Training'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022654,
            name: 'Sherrin DPM40AU EWP'
          },
          startDate: '2011-10-11T00:00:00.000Z',
          endDate: '2013-10-10T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022636,
            name: 'Electrical Appreciation'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '2012-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036187,
            name: 'Introduction to 5S'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036188,
            name: 'Introduction to True Blue'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036189,
            name: 'Conduct True Blue Problem Solving'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2012-01-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30022127,
            name: 'CV-Vehicle Loading Crane (>10 Metre)'
          },
          startDate: '2012-06-17T00:00:00.000Z',
          endDate: '2013-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022438,
            name: 'Hiab Hi-Duo 122 Vehicle Loading Crane'
          },
          startDate: '2012-06-17T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126389,
            name: 'FMG - Vehicle Loading Crane >10T1M'
          },
          startDate: '2012-06-17T00:00:00.000Z',
          endDate: '2017-06-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126390,
            name: 'FMG - Vehicle Loading Crane less 10T1M'
          },
          startDate: '2012-06-17T00:00:00.000Z',
          endDate: '2017-06-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022123,
            name: 'DG - Dogging'
          },
          startDate: '2012-06-18T00:00:00.000Z',
          endDate: '2017-04-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022426,
            name: 'JLG 1250 EWP'
          },
          startDate: '2012-10-31T00:00:00.000Z',
          endDate: '2014-10-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2013-02-07T00:00:00.000Z',
          endDate: '2014-02-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2013-06-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30060524,
            name: 'Hearing Conservation Awareness (WBT)'
          },
          startDate: '2013-06-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035294,
            name: 'Safety Accountability Section 44'
          },
          startDate: '2013-06-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022124,
            name: 'LF - Forklift Truck'
          },
          startDate: '2013-07-01T00:00:00.000Z',
          endDate: '2014-09-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2013-07-01T00:00:00.000Z',
          endDate: '2017-04-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022127,
            name: 'CV-Vehicle Loading Crane (>10 Metre)'
          },
          startDate: '2013-07-01T00:00:00.000Z',
          endDate: '2018-06-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022588,
            name: 'CB Light Vehicle Driving Permit'
          },
          startDate: '2013-07-02T00:00:00.000Z',
          endDate: '2016-09-30T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2013-07-02T00:00:00.000Z',
          endDate: '2018-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30207027,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2013-07-02T00:00:00.000Z',
          endDate: '2018-07-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154584,
            name: 'Light Vehicle Permit Practical'
          },
          startDate: '2013-07-02T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154585,
            name: 'Light Vehicle Permit Orientation - CB'
          },
          startDate: '2013-07-02T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30052639,
            name: 'Chichester Induction - Electrical Worker'
          },
          startDate: '2013-07-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2013-07-29T00:00:00.000Z',
          endDate: '2016-02-17T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022076,
            name: 'Electrician’s Licence'
          },
          startDate: '2013-08-21T00:00:00.000Z',
          endDate: '2018-06-26T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036327,
            name: 'FMG Job Hazard Analysis (incl. 5 Step)'
          },
          startDate: '2013-08-22T00:00:00.000Z',
          endDate: '2016-07-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064112,
            name: 'FMG Isolation & Tagging - Bridging (WBT)'
          },
          startDate: '2013-08-22T00:00:00.000Z',
          endDate: '2016-09-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064099,
            name: 'FMG Job Hazard Analysis Bridging (WBT)'
          },
          startDate: '2013-08-22T00:00:00.000Z',
          endDate: '2016-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2013-08-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2013-09-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30090488,
            name: 'High Voltage Switching Operator Induct'
          },
          startDate: '2013-10-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037746,
            name: 'FMG Isolation & Tag - Iso Officer-Theory'
          },
          startDate: '2014-01-26T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30046298,
            name: 'FMG Isolation & Tag - Iso Officer-Prac'
          },
          startDate: '2014-03-21T00:00:00.000Z',
          endDate: '2016-03-20T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089262,
            name: 'FMG Isolation Officer - Level 2 - Prac'
          },
          startDate: '2014-03-21T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089263,
            name: 'FMG Isolation Officer - Level 1 - Prac'
          },
          startDate: '2014-03-21T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096544,
            name: 'FMG Isolation Officer - Level 1 - Theory'
          },
          startDate: '2014-03-21T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096545,
            name: 'FMG Isolation Officer - Level 2 - Theory'
          },
          startDate: '2014-03-21T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2014-03-23T00:00:00.000Z',
          endDate: '2019-03-22T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022124,
            name: 'LF - Forklift Truck'
          },
          startDate: '2014-09-07T00:00:00.000Z',
          endDate: '2017-04-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30067539,
            name: 'Provide First Aid'
          },
          startDate: '2014-10-01T00:00:00.000Z',
          endDate: '2017-09-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2014-10-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30107362,
            name: 'TETRA Radio Basic User'
          },
          startDate: '2014-12-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2015-02-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30046262,
            name: 'Conduct Telescopic Handler Operations'
          },
          startDate: '2015-03-20T00:00:00.000Z',
          endDate: '2020-03-19T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126385,
            name: 'FMG - EWP Trailer/Scissor Lift'
          },
          startDate: '2015-08-22T00:00:00.000Z',
          endDate: '2017-08-21T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2015-08-22T00:00:00.000Z',
          endDate: '2017-08-21T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2015-12-27T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137523,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-10-17T00:00:00.000Z',
          endDate: '2016-12-12T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-10-17T00:00:00.000Z',
          endDate: '2018-10-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108283,
            name: 'Excavation and Penetration Online'
          },
          startDate: '2016-10-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30051052,
            name: 'Fire Awareness Training'
          },
          startDate: '2016-11-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089120,
            name: 'Hot Works – Certificate Holder/Issuer'
          },
          startDate: '2016-11-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022123,
            name: 'DG - Dogging'
          },
          startDate: '2017-04-10T00:00:00.000Z',
          endDate: '2018-07-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022124,
            name: 'LF - Forklift Truck'
          },
          startDate: '2017-04-10T00:00:00.000Z',
          endDate: '2018-07-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2017-04-10T00:00:00.000Z',
          endDate: '2018-07-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2017-10-17T00:00:00.000Z',
          endDate: '2018-10-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30146544,
            name: 'Low Voltage Rescue Training'
          },
          startDate: '2017-10-17T00:00:00.000Z',
          endDate: '2020-10-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30117532,
            name: 'Recipient in Charge'
          },
          startDate: '2017-12-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30165395,
            name: 'Fortescue Operational Induction'
          },
          startDate: '2017-12-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30165476,
            name: 'Perth Office Induction'
          },
          startDate: '2017-12-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2018-08-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30182833,
            name: 'FMG Authorised Isolation Officer Level 2'
          },
          startDate: '2018-08-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183011,
            name: 'FMG Permit Coordinator Theory'
          },
          startDate: '2018-08-31T00:00:00.000Z',
          endDate: '2018-09-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183001,
            name: 'FMG Authorised Permit Holder/Cert Issuer'
          },
          startDate: '2018-08-31T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183002,
            name: 'FMG Isolation Officer L1 - Theory'
          },
          startDate: '2018-08-31T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183004,
            name: 'FMG Isolation Officer L2 - Theory'
          },
          startDate: '2018-08-31T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        }
      ]
    },
    '507892': {
      phone: '',
      address: '',
      sapId: 507892,
      name: 'Christopher Iain DONALDSON',
      type: 'permanent',
      position: {
        name: 'Electrician Experienced'
      },
      department: {
        name: 'Electrical Maintenance 2',
        sapId: 30084173
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30022072,
            name: 'Class MR Driver\'s Licence'
          },
          startDate: '2007-09-10T00:00:00.000Z',
          endDate: '2012-09-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022106,
            name: 'Conduct 5 Step/Job Hazard Analysis'
          },
          startDate: '2009-09-23T00:00:00.000Z',
          endDate: '2012-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30025284,
            name: 'Authorised Personal Lockholder Mine Site'
          },
          startDate: '2009-09-23T00:00:00.000Z',
          endDate: '2012-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30027114,
            name: 'Authorised Isolation Officer - Mine Site'
          },
          startDate: '2009-09-27T00:00:00.000Z',
          endDate: '2012-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022588,
            name: 'CB Light Vehicle Driving Permit'
          },
          startDate: '2009-10-11T00:00:00.000Z',
          endDate: '2011-10-10T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30022590,
            name: 'CB Level 3 Pit Permit'
          },
          startDate: '2009-10-11T00:00:00.000Z',
          endDate: '2012-11-10T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30022307,
            name: 'JLG33RTS Scissor Lift'
          },
          startDate: '2009-12-21T00:00:00.000Z',
          endDate: '2011-12-20T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022434,
            name: 'MHP 15/44 MK2 EWP'
          },
          startDate: '2009-12-21T00:00:00.000Z',
          endDate: '2011-12-20T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022108,
            name: 'Basic First Aid'
          },
          startDate: '2010-07-20T00:00:00.000Z',
          endDate: '2011-12-05T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2010-11-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022111,
            name: 'CB Induction - Online'
          },
          startDate: '2010-11-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022102,
            name: 'Issue Hot Work Permits'
          },
          startDate: '2010-12-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022438,
            name: 'Hiab Hi-Duo 122 Vehicle Loading Crane'
          },
          startDate: '2011-04-11T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126389,
            name: 'FMG - Vehicle Loading Crane >10T1M'
          },
          startDate: '2011-04-11T00:00:00.000Z',
          endDate: '2016-04-10T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022442,
            name: 'Kevrek 1000 Vehicle Loading Crane'
          },
          startDate: '2011-04-25T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022098,
            name: 'Authorised Snake Handler'
          },
          startDate: '2011-05-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022113,
            name: 'CC Induction - Online'
          },
          startDate: '2011-06-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036199,
            name: 'Fatigue Management Training - General'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '2014-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022112,
            name: 'CB Induction - Site'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022572,
            name: 'Create Notifications'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038956,
            name: 'Cross Cultural Awareness'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30047732,
            name: 'Body Smart Training'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30051052,
            name: 'Fire Awareness Training'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2011-09-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022108,
            name: 'Basic First Aid'
          },
          startDate: '2011-12-06T00:00:00.000Z',
          endDate: '2014-12-05T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022120,
            name: 'RB - Rigging  Basic'
          },
          startDate: '2011-12-08T00:00:00.000Z',
          endDate: '2016-12-07T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022159,
            name: 'Caterpillar IT62G Loader'
          },
          startDate: '2011-12-21T00:00:00.000Z',
          endDate: '2016-12-20T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30037746,
            name: 'FMG Isolation & Tag - Iso Officer-Theory'
          },
          startDate: '2012-04-27T00:00:00.000Z',
          endDate: '2012-07-27T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035179,
            name: 'Manitou 780 Telescopic Handler'
          },
          startDate: '2012-06-06T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126391,
            name: 'FMG - Telehandler'
          },
          startDate: '2012-06-06T00:00:00.000Z',
          endDate: '2017-06-05T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30045725,
            name: 'Caterpillar 962H Loader'
          },
          startDate: '2012-07-16T00:00:00.000Z',
          endDate: '2017-07-15T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30047702,
            name: 'CB Unrestricted Pit Permit'
          },
          startDate: '2012-10-08T00:00:00.000Z',
          endDate: '2017-10-06T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30047698,
            name: 'Hierarchy HV Operations'
          },
          startDate: '2012-11-06T00:00:00.000Z',
          endDate: '2012-12-02T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30047698,
            name: 'Hierarchy HV Operations'
          },
          startDate: '2012-12-03T00:00:00.000Z',
          endDate: '2014-11-05T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30035294,
            name: 'Safety Accountability Section 44'
          },
          startDate: '2013-03-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30072254,
            name: 'Cert IV Engineering'
          },
          startDate: '2013-04-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022388,
            name: 'JLG 4013 Telescopic Handler'
          },
          startDate: '2013-07-29T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022123,
            name: 'DG - Dogging'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-08T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022127,
            name: 'CV-Vehicle Loading Crane (>10 Metre)'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-08T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126385,
            name: 'FMG - EWP Trailer/Scissor Lift'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126386,
            name: 'FMG - EWP greater than 80F'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2013-08-09T00:00:00.000Z',
          endDate: '2018-08-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036327,
            name: 'FMG Job Hazard Analysis (incl. 5 Step)'
          },
          startDate: '2013-08-23T00:00:00.000Z',
          endDate: '2016-07-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064112,
            name: 'FMG Isolation & Tagging - Bridging (WBT)'
          },
          startDate: '2013-08-23T00:00:00.000Z',
          endDate: '2016-09-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064099,
            name: 'FMG Job Hazard Analysis Bridging (WBT)'
          },
          startDate: '2013-08-23T00:00:00.000Z',
          endDate: '2016-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2013-08-23T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037746,
            name: 'FMG Isolation & Tag - Iso Officer-Theory'
          },
          startDate: '2013-08-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2013-09-08T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2014-04-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022072,
            name: 'Class MR Driver\'s Licence'
          },
          startDate: '2014-05-05T00:00:00.000Z',
          endDate: '2017-09-10T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022076,
            name: 'Electrician’s Licence'
          },
          startDate: '2014-05-06T00:00:00.000Z',
          endDate: '2017-10-05T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30059592,
            name: 'Geotechnical Awareness Training'
          },
          startDate: '2014-06-07T00:00:00.000Z',
          endDate: '2016-06-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089263,
            name: 'FMG Isolation Officer - Level 1 - Prac'
          },
          startDate: '2014-08-09T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064842,
            name: 'Perform CPR HLTCPR211A'
          },
          startDate: '2014-10-08T00:00:00.000Z',
          endDate: '2017-10-07T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30029048,
            name: 'Maintenance Technician Fundamentals'
          },
          startDate: '2014-12-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2014-12-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30072264,
            name: 'Electrical Workers Competency'
          },
          startDate: '2015-05-16T00:00:00.000Z',
          endDate: '2017-05-15T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30118573,
            name: 'Managing Fatigue Employee - 2:1 Roster'
          },
          startDate: '2015-05-26T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2015-07-07T00:00:00.000Z',
          endDate: '2020-07-05T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30121242,
            name: 'CB Trailer Hitching and Towing'
          },
          startDate: '2015-07-11T00:00:00.000Z',
          endDate: '2016-10-23T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30157374,
            name: 'Trailer Hitching and Towing'
          },
          startDate: '2015-07-11T00:00:00.000Z',
          endDate: '2020-07-10T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2015-11-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126344,
            name: 'FMG - EWP Theory'
          },
          startDate: '2015-11-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2016-01-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2016-01-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30090488,
            name: 'High Voltage Switching Operator Induct'
          },
          startDate: '2016-02-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022124,
            name: 'LF - Forklift Truck'
          },
          startDate: '2016-02-24T00:00:00.000Z',
          endDate: '2021-02-24T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126380,
            name: 'FMG - Forklift up to and including 10T'
          },
          startDate: '2016-02-24T00:00:00.000Z',
          endDate: '2021-02-24T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126343,
            name: 'FMG - Forklift Theory'
          },
          startDate: '2016-02-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30129753,
            name: 'Shutdown Induction - Online'
          },
          startDate: '2016-04-21T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137523,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-04-22T00:00:00.000Z',
          endDate: '2016-12-12T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-04-22T00:00:00.000Z',
          endDate: '2018-04-21T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137841,
            name: 'Working at Heights - Certificate Holder'
          },
          startDate: '2016-04-24T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126346,
            name: 'FMG - Overhead Crane Theory'
          },
          startDate: '2016-04-25T00:00:00.000Z',
          endDate: '2018-04-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30111831,
            name: 'Fortescue Leader Induction Online'
          },
          startDate: '2016-04-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126348,
            name: 'FMG - Telehander Theory'
          },
          startDate: '2016-04-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108283,
            name: 'Excavation and Penetration Online'
          },
          startDate: '2016-10-08T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30046306,
            name: 'Welding Electrical Safety'
          },
          startDate: '2016-11-06T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089120,
            name: 'Hot Works – Certificate Holder/Issuer'
          },
          startDate: '2016-11-06T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30044176,
            name: 'FMG Permit Holder/Certificate Issuer'
          },
          startDate: '2016-12-09T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2017-04-17T00:00:00.000Z',
          endDate: '2018-04-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30146544,
            name: 'Low Voltage Rescue Training'
          },
          startDate: '2017-06-30T00:00:00.000Z',
          endDate: '2020-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30047702,
            name: 'CB Unrestricted Pit Permit'
          },
          startDate: '2017-10-07T00:00:00.000Z',
          endDate: '2018-02-07T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2018-04-17T00:00:00.000Z',
          endDate: '2018-06-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30117532,
            name: 'Recipient in Charge'
          },
          startDate: '2018-04-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126345,
            name: 'FMG - Dogging Theory'
          },
          startDate: '2018-07-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30208682,
            name: 'FMG - Overhead Crane Theory'
          },
          startDate: '2018-07-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        }
      ]
    },
    '524535': {
      phone: '',
      address: '',
      sapId: 524535,
      name: 'Suluvenusi KOROIWAQALEVU',
      type: 'permanent',
      position: {
        name: 'Electrician Experienced'
      },
      department: {
        name: 'Electrical Maintenance 3',
        sapId: 30000452
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2012-11-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022113,
            name: 'CC Induction - Online'
          },
          startDate: '2012-11-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022116,
            name: 'Solomon Induction - Online'
          },
          startDate: '2012-11-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022585,
            name: 'Perth Induction - Online'
          },
          startDate: '2012-11-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30032258,
            name: 'Exploration Induction - Online'
          },
          startDate: '2012-11-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042590,
            name: 'Respect in our Workplace'
          },
          startDate: '2012-11-16T00:00:00.000Z',
          endDate: '2013-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042591,
            name: 'Welcome to Fortescue'
          },
          startDate: '2012-11-16T00:00:00.000Z',
          endDate: '2014-11-15T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036327,
            name: 'FMG Job Hazard Analysis (incl. 5 Step)'
          },
          startDate: '2012-11-16T00:00:00.000Z',
          endDate: '2016-07-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2012-11-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2012-11-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2013-09-20T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30068844,
            name: 'CC Stockyard/OPF Area Specific Induction'
          },
          startDate: '2013-10-07T00:00:00.000Z',
          endDate: '2016-05-30T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30044176,
            name: 'FMG Permit Holder/Certificate Issuer'
          },
          startDate: '2013-10-13T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2018-01-29T00:00:00.000Z',
          endDate: '2020-01-28T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2018-01-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2018-01-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30165395,
            name: 'Fortescue Operational Induction'
          },
          startDate: '2018-01-29T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042591,
            name: 'Welcome to Fortescue'
          },
          startDate: '2018-02-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2018-02-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2018-02-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2018-02-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2018-02-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2018-02-14T00:00:00.000Z',
          endDate: '2018-02-27T00:00:00.000Z',
          proficiency: 'Theory (33%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2018-02-27T00:00:00.000Z',
          endDate: '2018-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30207027,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2018-02-27T00:00:00.000Z',
          endDate: '2023-02-26T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137841,
            name: 'Working at Heights - Certificate Holder'
          },
          startDate: '2018-02-27T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2018-02-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2018-03-01T00:00:00.000Z',
          endDate: '2023-03-04T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154584,
            name: 'Light Vehicle Permit Practical'
          },
          startDate: '2018-03-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154585,
            name: 'Light Vehicle Permit Orientation - CB'
          },
          startDate: '2018-03-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2018-03-23T00:00:00.000Z',
          endDate: '2018-03-23T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2018-04-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183002,
            name: 'FMG Isolation Officer L1 - Theory'
          },
          startDate: '2018-04-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183004,
            name: 'FMG Isolation Officer L2 - Theory'
          },
          startDate: '2018-09-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022076,
            name: 'Electrician’s Licence'
          },
          startDate: '2018-10-15T00:00:00.000Z',
          endDate: '2021-05-13T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        }
      ]
    },
    '534333': {
      phone: '',
      address: '',
      sapId: 534333,
      name: 'Callam Edward WALPOLE',
      type: 'permanent',
      position: {
        name: 'Electrician Experienced'
      },
      department: {
        name: 'Electrical Maintenance 2',
        sapId: 30084173
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2014-01-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022111,
            name: 'CB Induction - Online'
          },
          startDate: '2014-01-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2014-01-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042591,
            name: 'Welcome to Fortescue'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '2016-02-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30036327,
            name: 'FMG Job Hazard Analysis (incl. 5 Step)'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '2016-07-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038956,
            name: 'Cross Cultural Awareness'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2014-02-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022633,
            name: 'Confined Space Entry'
          },
          startDate: '2014-02-14T00:00:00.000Z',
          endDate: '2019-02-13T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2014-02-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2014-02-25T00:00:00.000Z',
          endDate: '2018-02-08T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022076,
            name: 'Electrician’s Licence'
          },
          startDate: '2014-02-25T00:00:00.000Z',
          endDate: '2018-06-24T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30080471,
            name: 'OPF Radiation Awareness'
          },
          startDate: '2014-02-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022588,
            name: 'CB Light Vehicle Driving Permit'
          },
          startDate: '2014-02-28T00:00:00.000Z',
          endDate: '2016-09-30T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2014-02-28T00:00:00.000Z',
          endDate: '2018-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30207027,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2014-02-28T00:00:00.000Z',
          endDate: '2019-02-27T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154584,
            name: 'Light Vehicle Permit Practical'
          },
          startDate: '2014-02-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154585,
            name: 'Light Vehicle Permit Orientation - CB'
          },
          startDate: '2014-02-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30064099,
            name: 'FMG Job Hazard Analysis Bridging (WBT)'
          },
          startDate: '2014-03-15T00:00:00.000Z',
          endDate: '2016-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30060524,
            name: 'Hearing Conservation Awareness (WBT)'
          },
          startDate: '2014-03-15T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2014-04-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2014-05-26T00:00:00.000Z',
          endDate: '2016-05-25T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2014-06-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089263,
            name: 'FMG Isolation Officer - Level 1 - Prac'
          },
          startDate: '2014-08-28T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096544,
            name: 'FMG Isolation Officer - Level 1 - Theory'
          },
          startDate: '2014-08-28T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30093865,
            name: 'CB Isolation and Tagging Awareness'
          },
          startDate: '2014-08-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064842,
            name: 'Perform CPR HLTCPR211A'
          },
          startDate: '2014-10-10T00:00:00.000Z',
          endDate: '2017-10-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2014-10-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022123,
            name: 'DG - Dogging'
          },
          startDate: '2014-11-10T00:00:00.000Z',
          endDate: '2019-11-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022143,
            name: 'High Voltage Switching'
          },
          startDate: '2014-11-24T00:00:00.000Z',
          endDate: '2019-11-23T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30101469,
            name: 'Step Right Programme'
          },
          startDate: '2014-12-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022425,
            name: 'JLG 450 AJ EWP'
          },
          startDate: '2015-01-04T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2015-01-04T00:00:00.000Z',
          endDate: '2017-01-03T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2015-01-04T00:00:00.000Z',
          endDate: '2020-01-03T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30092089,
            name: 'Generic EWP - WP Theory'
          },
          startDate: '2015-02-27T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022426,
            name: 'JLG 1250 EWP'
          },
          startDate: '2015-03-27T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022602,
            name: 'JLG 600 AJ EWP'
          },
          startDate: '2015-03-27T00:00:00.000Z',
          endDate: '2015-10-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126385,
            name: 'FMG - EWP Trailer/Scissor Lift'
          },
          startDate: '2015-03-27T00:00:00.000Z',
          endDate: '2017-03-26T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126386,
            name: 'FMG - EWP greater than 80F'
          },
          startDate: '2015-03-27T00:00:00.000Z',
          endDate: '2017-03-26T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30090488,
            name: 'High Voltage Switching Operator Induct'
          },
          startDate: '2015-07-07T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2015-12-27T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2016-06-01T00:00:00.000Z',
          endDate: '2021-05-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137523,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-10-09T00:00:00.000Z',
          endDate: '2016-12-12T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-10-09T00:00:00.000Z',
          endDate: '2018-10-08T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108283,
            name: 'Excavation and Penetration Online'
          },
          startDate: '2016-10-09T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2017-10-17T00:00:00.000Z',
          endDate: '2018-10-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30146544,
            name: 'Low Voltage Rescue Training'
          },
          startDate: '2017-10-17T00:00:00.000Z',
          endDate: '2020-10-16T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089120,
            name: 'Hot Works – Certificate Holder/Issuer'
          },
          startDate: '2018-03-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30107362,
            name: 'TETRA Radio Basic User'
          },
          startDate: '2018-03-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126344,
            name: 'FMG - EWP Theory'
          },
          startDate: '2018-03-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137841,
            name: 'Working at Heights - Certificate Holder'
          },
          startDate: '2018-03-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2018-08-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30051052,
            name: 'Fire Awareness Training'
          },
          startDate: '2018-08-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2018-08-19T00:00:00.000Z',
          endDate: '2023-02-08T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30212383,
            name: 'Fortescue Online Induction'
          },
          startDate: '2018-09-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        }
      ]
    },
    '542487': {
      phone: '',
      address: '',
      sapId: 542487,
      name: 'Leon Kelsey SAUNDERS',
      type: 'contractor',
      position: {
        name: 'Electrician Experienced'
      },
      department: {
        name: 'Electrical Maintenance 1',
        sapId: 30000453
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2014-08-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022116,
            name: 'Solomon Induction - Online'
          },
          startDate: '2014-08-19T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2014-08-19T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30095265,
            name: 'Solomon Induction - OPF'
          },
          startDate: '2014-08-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022113,
            name: 'CC Induction - Online'
          },
          startDate: '2016-08-07T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2016-08-07T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2016-08-07T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042591,
            name: 'Welcome to Fortescue'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2016-08-11T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137523,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '2016-12-12T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '2018-08-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30046306,
            name: 'Welding Electrical Safety'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30051052,
            name: 'Fire Awareness Training'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30083674,
            name: 'Ethical Behaviour at Fortescue - Online'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30085466,
            name: 'Environmental Legislation - Online'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089120,
            name: 'Hot Works – Certificate Holder/Issuer'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108283,
            name: 'Excavation and Penetration Online'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108420,
            name: 'CC Induction - Fixed Plant Operations'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137841,
            name: 'Working at Heights - Certificate Holder'
          },
          startDate: '2016-08-30T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126347,
            name: 'FMG - Skidsteer Theory'
          },
          startDate: '2016-09-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30043333,
            name: 'Electrical Substation Access'
          },
          startDate: '2016-09-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30126343,
            name: 'FMG - Forklift Theory'
          },
          startDate: '2016-09-19T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126344,
            name: 'FMG - EWP Theory'
          },
          startDate: '2016-09-19T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126348,
            name: 'FMG - Telehander Theory'
          },
          startDate: '2016-09-19T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30034612,
            name: 'Christmas Creek Site Access Permit'
          },
          startDate: '2016-09-20T00:00:00.000Z',
          endDate: '2016-09-20T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2016-09-20T00:00:00.000Z',
          endDate: '2017-03-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30034612,
            name: 'Christmas Creek Site Access Permit'
          },
          startDate: '2016-09-21T00:00:00.000Z',
          endDate: '2016-09-30T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2016-09-21T00:00:00.000Z',
          endDate: '2017-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30154583,
            name: 'Light Vehicle Permit Orientation - CC'
          },
          startDate: '2016-09-21T00:00:00.000Z',
          endDate: '2021-09-19T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154584,
            name: 'Light Vehicle Permit Practical'
          },
          startDate: '2016-09-21T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30029048,
            name: 'Maintenance Technician Fundamentals'
          },
          startDate: '2016-11-10T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '2019-04-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126385,
            name: 'FMG - EWP Trailer/Scissor Lift'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '2019-04-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126386,
            name: 'FMG - EWP greater than 80F'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '2019-04-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '2019-04-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126349,
            name: 'FMG - Truck Theory'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126376,
            name: 'FMG - Bus Theory'
          },
          startDate: '2016-11-15T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096544,
            name: 'FMG Isolation Officer - Level 1 - Theory'
          },
          startDate: '2017-01-18T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038956,
            name: 'Cross Cultural Awareness'
          },
          startDate: '2017-02-06T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30141834,
            name: 'SAP Work Manager Fundamentals'
          },
          startDate: '2017-02-25T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30165395,
            name: 'Fortescue Operational Induction'
          },
          startDate: '2017-03-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126346,
            name: 'FMG - Overhead Crane Theory'
          },
          startDate: '2017-04-12T00:00:00.000Z',
          endDate: '2018-04-29T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30107362,
            name: 'TETRA Radio Basic User'
          },
          startDate: '2017-04-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30163238,
            name: 'Workstation Ergonomics'
          },
          startDate: '2017-04-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30169874,
            name: 'Confined Space Certificate Holder Online'
          },
          startDate: '2017-04-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2017-04-20T00:00:00.000Z',
          endDate: '2018-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30207027,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2017-04-20T00:00:00.000Z',
          endDate: '2022-04-19T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30111831,
            name: 'Fortescue Leader Induction Online'
          },
          startDate: '2017-04-20T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2017-04-22T00:00:00.000Z',
          endDate: '2018-03-11T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154587,
            name: 'Light Vehicle Permit Orientation - SOL'
          },
          startDate: '2017-04-22T00:00:00.000Z',
          endDate: '2022-04-21T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30183001,
            name: 'FMG Authorised Permit Holder/Cert Issuer'
          },
          startDate: '2017-09-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2017-11-09T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183004,
            name: 'FMG Isolation Officer L2 - Theory'
          },
          startDate: '2017-12-02T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30182833,
            name: 'FMG Authorised Isolation Officer Level 2'
          },
          startDate: '2018-02-03T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2018-02-08T00:00:00.000Z',
          endDate: '2023-02-07T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2018-09-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30212383,
            name: 'Fortescue Online Induction'
          },
          startDate: '2018-09-17T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2018-09-28T00:00:00.000Z',
          endDate: '2020-09-27T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038859,
            name: 'Major Hazard Leadership Awareness Online'
          },
          startDate: '2018-09-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30112183,
            name: 'CB Workshop Washpad Familiarisation'
          },
          startDate: '2018-09-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30164011,
            name: 'CB Induction - Combined Workshops Theory'
          },
          startDate: '2018-09-28T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183002,
            name: 'FMG Isolation Officer L1 - Theory'
          },
          startDate: '2018-10-01T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154585,
            name: 'Light Vehicle Permit Orientation - CB'
          },
          startDate: '2018-10-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        }
      ]
    },
    '562227': {
      phone: '',
      address: '',
      sapId: 562227,
      name: 'Nicol Hannah DOWER',
      type: 'permanent',
      position: {
        name: 'Electrician Entry'
      },
      department: {
        name: 'Electrical Maintenance 2',
        sapId: 30084173
      },
      employeeCompetencies: [
        {
          competency: {
            sapId: 30072250,
            name: 'Cert III Electrotechnology Electrician'
          },
          startDate: '2016-09-20T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022110,
            name: 'General Induction - Online'
          },
          startDate: '2016-12-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022111,
            name: 'CB Induction - Online'
          },
          startDate: '2016-12-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035293,
            name: 'Hearing Conservation Awareness'
          },
          startDate: '2016-12-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30084107,
            name: 'Alcohol and Other Drugs Awareness Module'
          },
          startDate: '2016-12-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096393,
            name: 'Respect in the Workplace - Online'
          },
          startDate: '2016-12-18T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30037745,
            name: 'FMG Isolation & Tagging - Individual'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042591,
            name: 'Welcome to Fortescue'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30042615,
            name: 'Managing Fatigue - General'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30064698,
            name: 'FMG - Life Saving Choices'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30130749,
            name: 'Aboriginal Engagement'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30131485,
            name: 'FMG Five Step and Job Hazard Analysis'
          },
          startDate: '2016-12-22T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30161872,
            name: 'Electrical Worker Induction'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '2019-01-03T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035878,
            name: 'CB Induction - OPF'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30051052,
            name: 'Fire Awareness Training'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30071285,
            name: 'Respect in the Workplace-Leaders Online'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089120,
            name: 'Hot Works – Certificate Holder/Issuer'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30108283,
            name: 'Excavation and Penetration Online'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30137841,
            name: 'Working at Heights - Certificate Holder'
          },
          startDate: '2017-01-04T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154586,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2017-01-26T00:00:00.000Z',
          endDate: '2018-04-19T00:00:00.000Z',
          proficiency: 'Theory (50%)'
        },
        {
          competency: {
            sapId: 30207027,
            name: 'Light Vehicle Permit Online Module'
          },
          startDate: '2017-01-26T00:00:00.000Z',
          endDate: '2022-01-25T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022069,
            name: 'Class C Driver\'s Licence'
          },
          startDate: '2017-02-16T00:00:00.000Z',
          endDate: '2022-02-09T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30154584,
            name: 'Light Vehicle Permit Practical'
          },
          startDate: '2017-02-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30154585,
            name: 'Light Vehicle Permit Orientation - CB'
          },
          startDate: '2017-02-16T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Final (100%)'
        },
        {
          competency: {
            sapId: 30107362,
            name: 'TETRA Radio Basic User'
          },
          startDate: '2017-03-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126344,
            name: 'FMG - EWP Theory'
          },
          startDate: '2017-03-14T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30096544,
            name: 'FMG Isolation Officer - Level 1 - Theory'
          },
          startDate: '2017-03-30T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30089263,
            name: 'FMG Isolation Officer - Level 1 - Prac'
          },
          startDate: '2017-03-31T00:00:00.000Z',
          endDate: '2018-01-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30038859,
            name: 'Major Hazard Leadership Awareness Online'
          },
          startDate: '2017-03-31T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30080399,
            name: 'HV Switching Operations - Certificate'
          },
          startDate: '2017-04-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022632,
            name: 'Working at Heights'
          },
          startDate: '2017-06-02T00:00:00.000Z',
          endDate: '2022-06-01T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2017-06-04T00:00:00.000Z',
          endDate: '2017-07-28T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2017-06-04T00:00:00.000Z',
          endDate: '2017-07-28T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126385,
            name: 'FMG - EWP Trailer/Scissor Lift'
          },
          startDate: '2017-06-23T00:00:00.000Z',
          endDate: '2017-07-28T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126386,
            name: 'FMG - EWP greater than 80F'
          },
          startDate: '2017-06-23T00:00:00.000Z',
          endDate: '2017-07-28T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30146544,
            name: 'Low Voltage Rescue Training'
          },
          startDate: '2017-07-12T00:00:00.000Z',
          endDate: '2018-07-22T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022137,
            name: 'WP - Boom Type Elevated Work Platform'
          },
          startDate: '2017-07-29T00:00:00.000Z',
          endDate: '2022-07-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126386,
            name: 'FMG - EWP greater than 80F'
          },
          startDate: '2017-07-29T00:00:00.000Z',
          endDate: '2022-07-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30126387,
            name: 'FMG - EWP up to and including 80F'
          },
          startDate: '2017-07-29T00:00:00.000Z',
          endDate: '2022-07-06T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30033327,
            name: 'CPR – Basic Resuscitation'
          },
          startDate: '2018-07-23T00:00:00.000Z',
          endDate: '2019-07-22T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30146544,
            name: 'Low Voltage Rescue Training'
          },
          startDate: '2018-07-23T00:00:00.000Z',
          endDate: '2021-07-22T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30178915,
            name: 'FMG Authorised Isolation Officer Level 1'
          },
          startDate: '2018-08-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183004,
            name: 'FMG Isolation Officer L2 - Theory'
          },
          startDate: '2018-08-13T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30022076,
            name: 'Electrician’s Licence'
          },
          startDate: '2018-08-22T00:00:00.000Z',
          endDate: '2021-12-12T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30035294,
            name: 'Safety Accountability Section 44'
          },
          startDate: '2018-09-05T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        },
        {
          competency: {
            sapId: 30183001,
            name: 'FMG Authorised Permit Holder/Cert Issuer'
          },
          startDate: '2018-10-12T00:00:00.000Z',
          endDate: '9999-12-31T00:00:00.000Z',
          proficiency: 'Yes (100%)'
        }
      ]
    }
  };
  // data: any = {};

  constructor() { }

  setData(res: Object): any {
    this.data = res;
  }

  getData(): any {
    return this.data;
  }
}
