import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styles: []
})
export class SigninComponent implements OnInit {

  authForm: FormGroup;
  isSubmitting = false;
  errors: string[];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.authForm = this.fb.group({
      'usernameOrEmail': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  submitForm() {
    this.isSubmitting = true;
    this.errors = undefined;

    const credentials = this.authForm.value;

    this.authenticationService
      .attemptAuth(credentials)
      .subscribe(
        data => this.router.navigateByUrl('/'),
        err => {
          this.errors = err;
          console.log(err);
          this.isSubmitting = false;
        }
      );
  }

}
