import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { HomeComponent } from '../home/home.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.authenticationService.isAuthenticated.pipe(
      take(1),
      map(result => {
        if (!result) {
          this.router.navigate(['/auth/signin']);
        } else {
          let currentUserRoles = this.authenticationService.currentUserValue.roles;
          if (route.data.role) {
            return route.data.role.includes(currentUserRoles[0]);
          } else {
            this.router.navigate(['/']);
            return false;
          }

        }

      }),
      catchError(err => {
        console.error(err);
        this.router.navigate(['/auth/signin']);
        return of(false);
      })
    );

  }
}
