import * as moment from 'moment';

export class Settings {
    id	: number;
    keyName	: string;
    value	: string;
    accountId	: number;
    created	: moment.Moment;
    updated	: moment.Moment;
}
