import * as moment from 'moment';

export class Timesheetapprovals {
    id	: number;
    step	: number;
    name	: string;
    email	: string;
    signature : string;
    created	: moment.Moment;
    updated	: moment.Moment;
    projectId	: number;
    userId	: number;
    requireSignIn	: boolean;
    accountId	: number;
}
