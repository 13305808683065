import * as moment from 'moment';

export class Entries {
    id	: number;
    description	: string;
    date : moment.Moment;
    hours : number;
    row	: number;
    created	: moment.Moment;
    updated	: moment.Moment;
    projectId	: number;
    taskId	: number;
    userId	: number;
    timesheetId	: number;
    startTime	: moment.Moment;
    endTime	: moment.Moment;
    breaks	: string;
    accountId	: number;
}
