import * as moment from 'moment';

export class Projects {
    id	: number;
    name	: string;
    code	: string;
    description	: string;
    startDate	: moment.Moment;
    endDate	: moment.Moment;
    length	: number;
    hoursPerDay	: number;
    unitOfTime : string;
    created	: moment.Moment;
    updated	: moment.Moment;
    deleted	: moment.Moment;
    clientId	: number;
    accountId	: number;
    fixedPrice	: boolean;
    completed	: boolean;
    poMinimum	: boolean;
    userInactive	: boolean;
    isLeaveProject	: boolean;
    isAllowanceProject	: boolean;
    address	: string;
    city	: string;
    state	: string;
    postalCode	: string;
    country	: string;
}
