import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/services';
import { Users } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['']
})
export class AppComponent implements OnInit {

  currentUser: Users;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.authenticationService.populate();

    this.authenticationService.currentUser.subscribe(user => this.currentUser = user);
  }
}
