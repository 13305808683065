import * as moment from 'moment';

export class Xeropayitems {
    id	: number;
    name	: string;
    itemType	: string;
    xeroItemName	: string;
    xeroItemReference	: string;
    accountId	: number;
    created	: moment.Moment;
    updated	: moment.Moment;
    taskId	: number;
}
