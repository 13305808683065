import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ClientsComponent } from './clients.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientsViewComponent } from './clients-view/clients-view.component';
import { ClientsCreateComponent } from './clients-create/clients-create.component';

const routes : Routes = [{
  path: 'clients',
  component : ClientsComponent,
  children : [
    {
      path: '', 
      component: ClientsListComponent
  },
  {
    path: 'create',
    component: ClientsCreateComponent
  },
  {
    path: 'archived',
    component: ClientsListComponent
  },
  {
    path: ':id',
    component: ClientsViewComponent
  },
  {
    path: ':id/edit',
    component: ClientsCreateComponent
  }
  ]
}]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
