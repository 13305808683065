import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-default',
  templateUrl: './config-default.component.html',
  styles: []
})
export class ConfigDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
