import * as moment from 'moment';

export class Projectusers {
    position	: string;
    payRate	: number;
    billRate	: number;
    created	: moment.Moment;
    updated	: moment.Moment;
    projectId	: number;
    userId	: number;
    allocatedHours	: number;
}
