import * as moment from 'moment';

export class Approvals {
    id	: number;
    step	: number;
    token	: string;
    key	: string;
    approved	: boolean;
    issueDate	:  moment.Moment;
    approvalDate	:  moment.Moment;
    override	: boolean;
    created	:  moment.Moment;
    updated	:  moment.Moment;
    timesheetId	: number;
    projectId	: number;
    assignedTo	: number;
    approvedByName	: string;
    approvedByEmail	: string;
    ipAddress	: string;
    accountId	: number;
}
