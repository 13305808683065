import * as moment from 'moment';
import { Role } from './role';

export class Users {
  id: Number
  email: string;
  username: string;
  token: string;
  firstName: string;
  lastName: string;
  password: string;
  salt: string;
  provider: string;
  roles: [Role];
  lastLogin: moment.Moment
  inviteToken: string;
  resetPasswordToken: string;
  resetPasswordExpires: string;
  xeroEmployeeId: string;
  created: moment.Moment;
  updated: moment.Moment;
  deleted: moment.Moment;
  timeInLieu: boolean;
  overtimeRate: Number;
}
