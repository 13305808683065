import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services';
import { Observable } from 'rxjs';
import { Clients, Projects } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private apiService:ApiService) { }

  getAll():Observable<Clients[]>{
    return this.apiService.get('/api/clients')
  }

  getOne(clientId):Observable<Clients>{
    return this.apiService.get('/api/clients/' + clientId)
  }

  getProjects(clientId):Observable<Projects[]>{
    return this.apiService.get('/api/clients/' + clientId + '/projects?all=1')
  }

  getArchived():Observable<Clients[]>{
    return this.apiService.get('/api/clients/archived')
  }

  create(body : Clients):Observable<Clients>{
    return this.apiService.post('/api/clients', body)
  }

  update(body : Clients):Observable<Clients>{
    return this.apiService.put('/api/clients/' + body.id, body)
  }

  restore(body: Clients):Observable<Clients>{
    return this.apiService.put('/api/clients/archived/' + body.id, body)
  }

  delete(body : Clients):Observable<Clients>{
    return this.apiService.delete('/api/clients/' + body.id)
  }

  permanentDelete(body: Clients):Observable<Clients>{
    return this.apiService.delete('/api/clients/archived/' + body.id)
  }
}
