import * as moment from 'moment';

export class Clients {  
    id	: number;
    name	: string;
    xeroContactId	: string;
    created	: moment.Moment;
    updated	: moment.Moment;
    deleted	: moment.Moment;
    accountId	: number;
    projects: number = 0;
}
