import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { AuthGuard } from './core/services';
import { Role } from './shared/models/role';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { role: [Role.admin, Role.reviewer, Role.user] } },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: { role: [Role.admin] },
    loadChildren: () => import('./users/users.module').then((m) => { return m.UsersModule })
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
