import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { ConfigModule } from './config/config.module';

import { AppComponent } from './app.component';
import { SyncModule } from './sync/sync.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AuthenticationService, AuthGuard } from './core/services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/services/error-interceptor';
import { TimesheetsModule } from './timesheets/timesheets.module';
import { ClientsModule } from './clients/clients.module';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    ConfigModule,
    SyncModule,
    AuthModule,
    CoreModule,
    TimesheetsModule,
    ClientsModule,
  ],
  providers: [AuthenticationService,AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
