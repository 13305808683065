import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'periodFormatter'
})
export class PeriodFormatterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const num = value.substring(0, value.length - 1);
    const plural = num > 1 ? 's' : '';
    switch (value.slice(-1).toLowerCase()) {
      case 'd':
        return `${num} Day${plural}`;
      case 'w':
        return `${num} Week${plural}`;
      case 'm':
        return `${num} Month${plural}`;
      case 'y':
        return `${num} Year${plural}`;
      default:
        return value;
    }

  }

}
