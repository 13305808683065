import * as moment from 'moment';

export class Accounts {
    id : number;
    name	: string;
    created	: moment.Moment;
    updated	: moment.Moment;
    deleted	: moment.Moment;
    billingCustomerId	: string;
    billingSubscriptionId	: string;
    locked : boolean;
}
