import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { SyncRoutingModule } from './sync-routing.module';
import { SyncComponent } from './sync.component';
import { SyncUploadComponent } from './sync-upload/sync-upload.component';

import { SyncDataService } from './sync-data.service';

@NgModule({
  declarations: [
    SyncComponent,
    SyncUploadComponent
  ],
  imports: [
    SharedModule,
    SyncRoutingModule
  ],
  providers: [SyncDataService]
})
export class SyncModule { }
