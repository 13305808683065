import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../shared/clients.service';
import { Clients, Projects} from 'src/app/shared/models'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clients-view',
  templateUrl: './clients-view.component.html',
  styles: ['.project-card{margin-top:10px;}.project-card:hover{background-color:	#A3A3A3}']
})
export class ClientsViewComponent implements OnInit {
  loading = true
  id = "";
  editing = false;
  client : Clients = new Clients;
  projectList : Projects[];
  private routeSub : Subscription
  constructor(private clientsService : ClientsService, private router : Router, private route : ActivatedRoute) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params =>{
      this.id = params['id']
    })
    this.clientsService.getOne(this.id).subscribe( (res)=>{
      this.client=res;
      this.client.projects=0
      this.clientsService.getProjects(this.client.id).subscribe( (res=>{
        this.projectList = res['data']
        this.client.projects = res['total']
      }))
    },
    (err)=>{

    },
    ()=>{
      this.loading=false;
    }
  )
  }

  ngOnDestroy(){
    this.routeSub.unsubscribe();
  }

  editClient(){
    this.router.navigateByUrl('clients/' + this.id + '/edit')
  }

  archiveClient(){
    this.clientsService.delete(this.client).subscribe((res) =>{
      this.router.navigateByUrl('clients/archived')
    })
  }

}
