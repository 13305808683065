import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Users } from '../../shared/models';
import { AuthenticationService } from '../services';
       
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  @Input() currentUser: Users;

  public navbarCollapsed = true;

  constructor(private userService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

  logout() {
    this.userService.purgeAuth();
    this.router.navigateByUrl('/auth/signin');
  }

}
