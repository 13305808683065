import { NgModule } from '@angular/core';
import { TimesheetsComponent } from './timesheets.component';
import { TimesheetsRoutingModule } from './timesheets-routing.module';
import { TimesheetListComponent } from './timesheet-list/timesheet-list.component';

@NgModule({
  declarations: [TimesheetsComponent, TimesheetListComponent],
  imports: [
    TimesheetsRoutingModule
  ]
})
export class TimesheetsModule { }
