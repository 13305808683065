import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

  // private formatErrors(error: any) {
  //   return Observable.throw(error.json());
  // }

  get(path: string, params = {}): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}${path}`, { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  put(path: string, body = {}, params = {}): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  post(path: string, body = {}, params = {}): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders(), params: params });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }

  delete(path): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}${path}`, { headers: this.setHeaders() });
      // .pipe(
      //   catchError(this.formatErrors),
      //   // map((res: HttpResponse<any>) => res.json())
      // );
  }
}
