import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../shared/clients.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Clients } from 'src/app/shared/models';

@Component({
  selector: 'app-clients-create',
  templateUrl: './clients-create.component.html',
  styles: []
})
export class ClientsCreateComponent implements OnInit {
  name : string;
  editing = false;
  loading = true;
  id = "";
  client : Clients = new Clients;
  private routeSub : Subscription;
  constructor(private clientsService : ClientsService, private router : Router, private route : ActivatedRoute) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params =>{
      if (params['id']){
        this.editing = true;
        this.id = params['id'];
        this.clientsService.getOne(this.id).subscribe( (res)=>{
          this.client=res;
          this.name = this.client.name;
        },
        (err)=>{
    
        },
        ()=>{
          this.loading=false;
        })
      }
    })
  }

  save(){
    if (this.editing){
      this.client.name = this.name;
      this.clientsService.update(this.client).subscribe((res)=>{
        this.router.navigateByUrl('clients/' + res.id);
      },
      (err) => {
        console.log("there was an error");
      })
    } else {
      this.client = new Clients
      this.client.name = this.name
      this.clientsService.create(this.client).subscribe((res)=>{
        this.router.navigateByUrl('clients/' + res.id)
      },
      (err)=>{
        console.log("There was an error")
      })
    }
  }

  cancel(){
    this.router.navigateByUrl('clients')
  }

}
